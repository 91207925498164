<template>
  <div class="uk-inline uk-">
    <template
      v-if="$slots.default">
      <slot />
    </template>
    <IconButton
      v-else
      :title="title"
      :tooltip="tooltip"
      :selected="selected"
      @click="$emit('click', $event)">
      <vk-icon
        :icon="icon"
        ratio="0.8"
      />
    </IconButton>
    <vk-drop
      mode="click"
      :position="dropPosition"
      style="position: fixed; top: 0; left: 0;">
      <vk-card
        padding="small"
        class="dropdown">
        <input
          v-model="keyword"
          class="uk-input search"
          :placeholder="$t('generic.search')"
        />
        <div class="checkbox-wrapper uk-margin-small">
          <label>
            <input
              type="checkbox"
              class="uk-checkbox checkbox"
              @input="toggleAll"
              :checked="allSelected">
            {{ $t('generic.select_all') }}
          </label>
        </div>
        <div
          :key="index"
          v-for="(value, index) in filteredValues"
          class="checkbox-wrapper uk-margin-small">
          <label>
            <input
              type="checkbox"
              class="uk-checkbox checkbox"
              @input="selectItem(value.id)"
              :checked="isSelected(value.id)">
            {{ value.name }}
          </label>
        </div>
        <div class="uk-margin-small-top">
          <slot name="button" />
        </div>
      </vk-card>
    </vk-drop>
  </div>
</template>

<script>
  import IconButton from '../projects/IconButton'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'icon',
      'title',
      'value',
      'values',
      'tooltip',
      'position',
      'selected',
    ],
    computed: {
      ...mapGetters({
        tooltipDelay: 'app/tooltipDelay'
      }),
      dropPosition() {
        return this.position
          ? this.position
          : 'bottom-right'
      },
      allSelected() {
        return this.selectedItems
          && this.values
          && this.selectedItems.length === this.values.length
      },
      filteredValues() {
        if (this.keyword) {
          return this.values.filter(value => value && value.name
            && value.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)
        }
        return this.values
      }
    },
    data() {
      return {
        keyword: null,
        selectedItems: []
      }
    },
    created() {
      this.selectedItems = this.value || []
    },
    methods: {
      toggleAll() {
        if (this.selectedItems
          && this.values
          && this.selectedItems.length === this.values.length) {
          this.selectedItems = []
        } else {
          this.selectedItems = this.values.map(value => value.id)
        }
        this.$emit('update', this.selectedItems)
      },
      isSelected(id) {
        return this.selectedItems.find(item => item === id)
      },
      selectItem(id) {
        if (!this.isSelected(id)) {
          this.selectedItems.push(id)
        } else {
          this.selectedItems = this.selectedItems.filter(item => item !== id)
        }
        this.$emit('update', this.selectedItems)
      },
    },
    components: {
      IconButton
    }
  }
</script>

<style lang="scss" scoped>
  .checkbox-wrapper {
    width: 100%;
    font-size: 15px;

    .checkbox {
      margin-right: 8px;
    }
  }

  .search {
    font-size: 15px;
  }
</style>