import Vue from 'vue'
import $store from '../store'
import i18n from '../locales'
import $http from 'superagent'
import $router from '../router'

export default {
  /**
   * Sends a HTTP-Get request to your api
   * @param url
   * @returns {*}
   */
  get(url) {
    return $http
    .get(this.prepare(url))
    .set('Accept', 'application/json')
    .set('api-token', $store.getters['auth/token'])
    .set('api-language', i18n.locale || i18n.fallbackLocale)
    .then(res => res.body)
    .then(res => this.handleResponse(res))
    .catch(error => this.handleErrorResponse(error))
  },
  /**
   * Sends a HTTP-Post with given body to your api
   * @param url
   * @param body
   */
  post(url, body) {
    return $http
    .post(this.prepare(url))
    .set('Accept', 'application/json')
    .set('api-token', $store.getters['auth/token'])
    .set('api-language', i18n.locale || i18n.fallbackLocale)
    .send(body)
    .then(res => res.body)
    .then(res => this.handleResponse(res))
    .catch(error => this.handleErrorResponse(error))
  },
  /**
   * Returns the URL modified with your api url
   * @param url
   * @returns {*}
   */
  prepare(url) {
    return process.env.VUE_APP_API_URL + url
  },
  /**
   * Handles incoming response and do some pre work
   * @param res
   */
  handleResponse(res) {
    if (res && res.success) {
      return res
    }

    if (res && res.error) {
      // Handle unauthenticated error
      if (res.code && res.code === 401) {
        Vue.toasted.error(i18n.t(res.error))
        $store.commit('auth/setToken', null)
        return $router.push('/' + i18n.locale + '/auth/login')
      }

      // Show $toasted error by translation
      if (i18n.te(res.error)) {
        Vue.toasted.error(i18n.t(res.error))
        return res
      }

      // Show $toasted if translation is missing/unknown error
      Vue.toasted.error(res.error)
      return res
    }
  },
  /**
   * Handles an error message
   * @param error
   */
  handleErrorResponse(error) {
    console.log(error)
  },
}