<template>
  <nav
      class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
  >
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        Seite
        <span class="font-medium">{{page}}</span>
      </p>
    </div>

    <div class="flex-1 flex justify-between sm:justify-end">
      <button
          class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-default"
          :disabled="page === 1"
          @click="$emit('prev')"
      >
        <
      </button>

      <button
          class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-default"
          :disabled="page > (total / perPage)"
          @click="$emit('next')"
      >
        >
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  props: [
      'page',
      'perPage',
      'total'
  ]
}
</script>
