import Domains from '../../views/web/Domains'
import Pages from '../../views/web/Pages'
import Providers from '../../views/web/Providers'
import Servers from '../../views/web/Servers'

export default [
    {
        path: '/:lang/:appId/domains/:domainId?',
        component: Domains,
        meta: {
            auth: true,
        }
    },
    {
        path: '/:lang/:appId/pages/:pageId?',
        component: Pages,
        meta: {
            auth: true,
        }
    },
    {
        path: '/:lang/:appId/providers/:providerId?',
        component: Providers,
        meta: {
            auth: true,
        }
    },
    {
        path: '/:lang/:appId/servers/:serverId?',
        component: Servers,
        meta: {
            auth: true,
        }
    },
]