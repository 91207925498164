<template>
  <form
    class="uk-form-stacked max-h-full"
    @submit.prevent="$emit('submit')">
    <div class="bg-white overflow-hidden shadow rounded-lg">
      <HeaderControl :title="title">
        <slot name="header-controls" />
      </HeaderControl>

      <div class="w-full overflow-y-auto pr-5 h-full px-4 py-5 sm:p-6">
        <slot />
      </div>

      <div class="px-4 py-4 sm:px-6" slot="footer" v-if="$slots['footer-controls']">
        <slot name="footer-controls" />
      </div>
    </div>
  </form>
</template>

<script>
  import HeaderControl from './HeaderControl'

  export default {
    props: [
      'title'
    ],
    components: {
      HeaderControl,
    }
  }
</script>