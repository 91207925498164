import Customers from '../../views/customers/Customers'

export default [
    {
        path: '/:lang/:appId/customers/:customerId?',
        component: Customers,
        meta: {
            auth: true,
        }
    }
]