<template>
	<div>
		<div class="table-header" v-if="showTableHeader">
			<h3 class="table-header-title" v-if="getConfigParam('title')">
				{{ getConfigParam('title') }} <slot name="controls" />
			</h3>
			<div class="search-box-wrapper" v-if="getConfigParam('search')">
				<div class="search-box">
					<div class="search-box-inner">
						<div class="search-box-icon">
							<Icon src="images/icons/search.svg"></Icon>
						</div>
						<input
							v-model="keyword"
							:placeholder="$t('generic.search')"
							type="text"
							class="search-box-input"
						/>
						<input
							v-model="keyword"
							:placeholder="$t('generic.search')"
							type="text"
							class="search-box-input-mobile"
						/>
					</div>
				</div>
			</div>
		</div>
		<template v-if="paginatedData && paginatedData.length > 0">
			<div class="flex flex-col">
				<div class="table-wrapper">
					<div class="table-wrapper-inner">
						<div class="table-wrapper-inner-shadow">
							<table class="table">
								<thead class="bg-gray-50">
									<tr>
										<th
											v-for="(header, index) in getConfigParam('headers')"
											:key="`head-${header.id}-${index}`"
											scope="col"
											@click="changeSort(header)">
											{{ header.label }}
											<span :class="getArrowClass(header.id)" class="sort-arrow" />
										</th>
									</tr>
								</thead>

								<tbody class="table-body">
									<tr
										v-for="(data, index) in paginatedData"
										:key="`${data.id}-${index}`"
										@click="selectRow(data.id)">
										<td
											v-for="(header, index) in getConfigParam('headers')"
											:key="`body-${header.id}-${index}`">
											<div class="flex items-center">
												<span
													:class="{
														[header.class]: header.class,
														['css_data_' + slugify(data[header.id])]: true
													}">
													<template v-if="header.filter">{{
														useFilter(header.filter, data[header.id])
													}}</template>
													<template v-else-if="data[header.id]">
														{{ $te(data[header.id]) ? $t(data[header.id]) : data[header.id] }}
													</template>
												</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<pagination
								v-if="getConfigParam('pagination')"
								:page.sync="page"
								:per-page="maxPageSize"
								:total="searchAndSortData.length"
								@next="next"
								@prev="prev"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>

		<div class="empty-message" v-else>
			{{ getConfigParam('emptyMessage') }}
		</div>
	</div>
</template>

<script>
  import Vue from 'vue'
  import pagination from '@/components/global/tailwind/Pagination'
  import Icon from '@/components/global/Icon'

  export default {
   props: ['data', 'title', 'config'],
   computed: {
    showTableHeader() {
     return (
      this.getConfigParam('title') ||
      this.getConfigParam('search') ||
      this.$slots.controls
     )
    },
    maxPageSize() {
     return this.getConfigParam('pageSize') || 20
    },
    searchAndSortData() {
     let data = this.data
     if (data) {
      if (this.keyword) {
       data = data.filter((item) => {
        return (
         Object.keys(item)
          .filter((item) => item !== 'id')
          .filter((property) => {
           if (item[property]) {
            let hasFilteredValue = false
            let headers = this.getConfigParam('headers')
            if (headers) {
             headers
              .filter((item) => item.filter)
              .map((item) => item.filter)
              .forEach((filter) => {
               let value = item[property].toString().toLowerCase()
               let filteredValue = this.$options.filters[filter](value)
               if (
                filteredValue &&
                filteredValue
                 .toString()
                 .toLowerCase()
                 .indexOf(this.keyword.toLowerCase()) > -1
               ) {
                hasFilteredValue = true
               }
              })
            }

            return (
             item[property] &&
             (item[property]
              .toString()
              .toLowerCase()
              .indexOf(this.keyword.toLowerCase()) > -1 ||
              hasFilteredValue)
            )
           }
          }).length > 0
        )
       })
      }

      let property = Object.keys(this.sortedBy)[0]
      let direction = this.sortedBy[property]
      if (property && direction) {
       data = data.sort((i1, i2) => {
        return direction === 'asc'
         ? i1[property] > i2[property]
          ? 1
          : -1
         : i1[property] < i2[property]
         ? 1
         : -1
       })
      }
     }
     return data
    },
    paginatedData() {
     let data = this.searchAndSortData
     if (this.pagination) {
      let currentRange = (this.page - 1) * this.maxPageSize
      data = data.slice(currentRange, currentRange + this.maxPageSize)
     }
     return data
    }
   },
   data() {
    return {
     page: 1,
     keyword: null,
     selectedRows: [],
     sortedBy: { [this.getConfigParam('headers')[0].id]: 'asc' }
    }
   },
   methods: {
    selectRow(id) {
     if (this.selectedRows.length == 0) {
      this.selectedRows = [id]
     } else {
      this.selectedRows[0] === id
       ? (this.selectedRows = [])
       : (this.selectedRows = [id])
     }
     let selectedData = this.data.find((item) => item.id === this.selectedRows[0])
     this.$emit('row-selected', selectedData)
    },
    getArrowClass(id) {
     return [
      !this.sortedBy.hasOwnProperty(id) ? 'hidden' : 'inline-block',
      this.sortedBy[id] === 'asc' ? 'rotate-180 transform' : 'rotate-0 transform'
     ]
    },
    prev() {
     if (this.page > 1) {
      this.page--
     }
    },
    next() {
     if (this.page < this.searchAndSortData / this.maxPageSize) {
      this.page++
     }
    },

    slugify(data) {
     if (data) {
      return data
       .toLowerCase()
       .replace(/ /g, '-')
       .replace(/[^\w-]+/g, '')
     }
    },

    /**
     * Retrieves the parameter from config.
     * @param param
     * @returns {*}
     */
    getConfigParam(param) {
     if (this.config && this.config[param]) {
      return this.config[param]
     }
    },
    useFilter(filter, data) {
     return this.$te(Vue.filter(filter)(data))
      ? this.$t(Vue.filter(filter)(data))
      : Vue.filter(filter)(data)
    },
    changeSort(header) {
     if (this.sortedBy.hasOwnProperty(header.id)) {
      if (this.sortedBy[header.id] === 'asc') {
       this.sortedBy = { [header.id]: 'desc' }
      } else {
       this.sortedBy = { [header.id]: 'asc' }
      }
     } else {
      this.sortedBy = { [header.id]: 'asc' }
     }
    }
   },
   watch: {
    selectedRows: {
     deep: true,
     handler() {
      let selectedData = this.data.find(
       (item) => item.id === this.selectedRows[0]
      )
      this.$emit('row-selected', selectedData)
     }
    }
   },
   components: {
    pagination,
    Icon
   }
  }
</script>

<style lang="scss" scoped>
  .arrow {
   background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cg%20fill%3D%22none%22%20stroke%3D%22%23999%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M9%2014.203V4.045%22%20%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M5.345%207.585L9%203.797l3.655%203.788%22%20%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
  }
  .table-header {
   @apply pb-5 sm:flex sm:items-center sm:justify-between;
   .table-header-title {
    @apply text-lg leading-6 font-medium text-gray-900;
   }
   .search-box-wrapper {
    @apply mt-3 sm:mt-0 sm:ml-4;
    .search-box {
     @apply flex rounded-md shadow-sm;
     .search-box-inner {
      @apply relative flex-grow focus-within:z-10;
      .search-box-icon {
       @apply absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none;
      }
      .search-box-input {
       @apply focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded pl-10 sm:hidden border-gray-300;
      }
      .search-box-input-mobile {
       @apply hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded pl-10 sm:block sm:text-sm border-gray-300;
      }
     }
    }
   }
  }
  .table-wrapper {
   @apply -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8;
   .table-wrapper-inner {
    @apply py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8;
    .table-wrapper-inner-shadow {
     @apply shadow overflow-hidden border-b border-gray-200 sm:rounded-lg;
     .table {
      @apply min-w-full divide-y divide-gray-200;
      th {
       @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
       .sort-arrow {
        @apply absolute bg-no-repeat bg-center duration-200 transition-all w-5 h-5 arrow;
       }
      }
      .table-body {
       @apply bg-white divide-y divide-gray-200;
       tr {
        @apply duration-200 transition-all hover:bg-purple-600 hover:text-white;
        td {
         @apply px-6 py-4 whitespace-nowrap;
        }
        &:hover {
         cursor: pointer;
        }
       }
      }
     }
    }
   }
  }
  .empty-message {
   @apply px-4 py-5 sm:px-6 flex justify-center m-12;
  }
</style>