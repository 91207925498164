<template>
  <a
    :href="href"
    class="border-box w-10 h-10 rounded-full bg-gray-50 text-gray-600 align-middle inline-flex justify-center items-center duration-200 transition-all hover:bg-gray-200 hover:outline-none hover:text-gray-600">
    <!-- TODO:: Replace icon -->
    <vk-icon
      :icon="icon"
      :ratio="ratio"
    />
  </a>
</template>

<script>
export default {
  props: ['href', 'icon', 'ratio']
}
</script>
