<template>
  <div
    class="w-full p-5"
    @click.stop="clickOnAvatar">
    <img
      :src="imageSource"
      class="w-48 h-48 p-1 mx-auto cursor-pointer hover:bg-indigo-600 rounded-full"
    />
    <input
      ref="file"
      type="file"
      class="hidden"
      @change="upload"
      accept=".png, .jpg, .jpeg"
    />
  </div>
</template>

<script>
  export default {
    props: [
      'path'
    ],
    computed: {
      imageSource() {
        return this.imageSrc
          || this.path
          || 'images/avatars/avatar_1.svg'
      }
    },
    data() {
      return {
        imageSrc: null,
      }
    },
    methods: {
      clickOnAvatar() {
        let file = this.$refs.file
        if (file) {
          file.click()
        }
      },
      upload($event) {
        let file = $event.target.files[0]
        if (file) {
          let fileReader = new FileReader()
          fileReader.onload = () => {
            this.imageSrc = fileReader.result
            this.$store.dispatch('app/uploadAvatar', {
              extension: file.name.match(/\.[0-9a-z]+$/i)[0],
              content: fileReader.result.split(',')[1],
              appId: this.$route.params.appId
            }).then(() => {
              this.$toasted.success(this.$t('app.avatar_upload_successful'))
            })
          }
          fileReader.readAsDataURL(file)
        }
      }
    },
  }
</script>