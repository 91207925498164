import TableHelper from '../helpers/table-headers'

export default {
  props: [
    'queryParam',
    /**
     *  The field foreignValues provides the values which are assigned to this object type
     *  (e.g. Server = ParentObject assigns Pages = ForeignValues) This means foreignValues
     *  are filteredValues base of the parent object
     */
    'foreignValues'
  ],
  methods: {
    /**
     * Creates the link to append the query param to the route
     * @returns {string}
     */
    createQueryParamLink(route) {
      let currentRoute = this.$route.path
      if (currentRoute.indexOf('?') > -1) {
        currentRoute += '&' + this.queryParam + '=' + route
      } else {
        currentRoute += '?' + this.queryParam + '=' + route
      }
      return currentRoute
    },
    /**
     * Creates a table config for slim tables
     * @param emptyMessage
     * @param headers
     * @returns {{emptyMessage: VueI18n.TranslateResult, headers: *, search: boolean, pagination: boolean, slim: boolean, pageSize: number}}
     */
    createTableConfig(emptyMessage, headers) {
      return {
        emptyMessage: this.$t(emptyMessage),
        headers: TableHelper[headers],
        pagination: true,
        pageSize: 20,
        search: true,
        slim: true,
      }
    },
    /**
     * Handles click on table cell
     * @param $event
     */
    async selectRow($event) {
      await this.$router.push({
        query: {
          [this.queryParam]: $event.id
        }
      })
    }
  }
}