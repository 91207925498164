import Vue from 'vue'
import VueI18n from "vue-i18n"

import de from './modules/de'
import en from './modules/en'

Vue.use(VueI18n)

export default new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages: {
        de,
        en
    }
})