import { render, staticRenderFns } from "./VkContainer.vue?vue&type=template&id=6c8a2440&scoped=true&"
import script from "./VkContainer.vue?vue&type=script&lang=js&"
export * from "./VkContainer.vue?vue&type=script&lang=js&"
import style0 from "./VkContainer.vue?vue&type=style&index=0&id=6c8a2440&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c8a2440",
  null
  
)

export default component.exports