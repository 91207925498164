import APIFactory from '../../api/factory'

export default {
  namespaced: true,
  state: {
    users: [],

    language: 'de',
    tooltipDelay: 1000,
    apiRefreshThreshold: process.env.VUE_APP_API_REFRESH_THRESHOLD,
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language
    },
    setUsers(state, users) {
      state.users = users
    }
  },
  actions: {
    search({commit}, payload) {
      return APIFactory.search(payload.appId, payload.keyword)
    },
    createUser({commit, dispatch}, payload) {
      return APIFactory.createUser(payload.appId, payload.email, payload.lastname, payload.firstname)
        .then((res) => dispatch('loadUsers', payload).then(() => res))
    },
    loadUsers({commit}, payload) {
      return APIFactory.findUsers(payload.appId).then(res => {
        if (res && res.success) {
          commit('setUsers', res.data)
        }
      })
    },
    deleteUser({commit, dispatch}, payload) {
      return APIFactory.deleteUser(payload.appId, payload.id).then(() => dispatch('loadUsers', payload))
    },
    uploadAvatar({commit, dispatch}, payload) {
      return APIFactory.uploadAvatar(payload.content, payload.extension).then((res) => {
        commit('auth/setAvatar', res.data.avatar, {root: true})
        return dispatch('loadUsers', payload).then(() => res)
      })
    }
  },
  getters: {
    users: (state) => state.users,
    language: (state) => state.language,
    tooltipDelay: (state) => state.tooltipDelay,
    apiRefreshThreshold: (state) => state.apiRefreshThreshold,
  }
}