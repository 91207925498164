<template>
  <button
    :type="type"
    class="p-0 border-0 bg-transparent">
    <vk-icon-button
      v-vk-tooltip="tooltip"
      :icon="icon"
    />
  </button>
</template>

<script>
  export default {
    props: [
      'type',
      'icon',
      'tooltip',
    ],
  }
</script>
