<template>
  <div class="user-wrapper">
    <div class="avatar">
      <img
        class="rounded-full"
        :src="avatar || 'images/avatars/avatar_1.svg'"
      />
    </div>
    <div class="user">
      <div class="fullname">{{ fullname }}</div>
      <div class="email">{{ email }}</div>
    </div>
    <div class="actions">
      <ConfirmModal
        :title="$t('app.confirm_delete_user_title')"
        :message="$t('app.confirm_delete_user_message')"
        @confirm="deleteUser">
        <vk-icon-button
          icon="trash"
          type="button"
        />
      </ConfirmModal>
    </div>
  </div>
</template>

<script>
  import VkIconButton from '../global/vuikit/VkIconButton'
  import ConfirmModal from "@/components/global/modals/ConfirmModal";

  export default {
    props: [
      'fullname',
      'avatar',
      'email',
      'id'
    ],
    methods: {
      deleteUser() {
        this.$store.dispatch('app/deleteUser', {
          appId: this.$route.params.appId,
          id: this.id
        }).then(res => {
          if (res && res.success) {
            this.$toasted.success(this.$t('app.account_deleted'))
          }
        })
      }
    },
    components: {
      ConfirmModal,
      VkIconButton
    }
  }
</script>

<style lang="scss" scoped>
  .user-wrapper {
    width: 100%;
    height: 72px;
    display: flex;
    margin-top: 16px;
    align-items: center;
    box-sizing: border-box;
    background: $color-primary;
    justify-content: space-between;

    .avatar {
      padding: 8px 16px;

      img {
        max-width: 48px;
        max-height: 48px;
      }
    }

    .user {
      width: 100%;

      .fullname {
        font-weight: 600;
      }
    }

    .actions {
      width: 72px;
      height: 72px;
      display: flex;
      flex-shrink: 0;
      padding: 8px 16px;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;
    }
  }
</style>