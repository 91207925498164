<template>
  <div
      class="flex cursor-pointer rounded-3xl bg-secondary hover:bg-gray-100 mb-1"
      :class="{
        'icon-button-wrapper': true,
        'bg-gray-100': selected
      }"
      @click.stop="$emit('click', $event)"
  >
    <div class="m-1 w-7 h-7 flex rounded-full items-center justify-center bg-primary">
      <slot />
    </div>

    <div
        v-if="title"
        class="flex select-none items-center pr-6 pl-3">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'selected'
  ]
}
</script>
