<template>
  <NavigationLayout :padding="true">
    <SplitLayout
      :route-param="routeParam"
      :query-param-map="queryParamMap"
      :route-component="components.routeComponent">
      <Table
        :config="config"
        :data="providers"
        @row-selected="rowSelected">
        <template slot="controls">
          <a
              :href="createNewObjectLink"
              type="button"
              ratio="0.85"
              icon="plus"
              class="inline-flex items-center p-0 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white"
          >
            <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
            </svg>
          </a>
        </template>
      </Table>
    </SplitLayout>
  </NavigationLayout>
</template>

<script>
  import NavigationLayout from '../../components/layout/NavigationLayout'
  import SplitLayout from '../../components/layout/SplitLayout'
  import SplitViewMixin from '../../mixins/split-view'
  import Provider from '../../views/web/Provider'
  import Table from '../../components/global/Table'
  import {mapGetters} from 'vuex'
  import Domain from '../../views/web/Domain'
  import Server from '../../views/web/Server'
  import Page from '../../views/web/Page'

  export default {
    computed: {
      ...mapGetters({
        providers: 'web/providers'
      })
    },
    data() {
      return {
        config: null,
      }
    },
    created() {
      this.setup('providers', 'providerId', Provider, {
        serverId: Server,
        domainId: Domain,
        pageId: Page,
      })
      this.config = this.createTableConfig('nav.providers', 'web.no_providers_found')
      this.loading = true
      this.$store.dispatch('web/loadProviders', {
        appId: this.$route.params.appId
      }).finally(() => this.loading = false)
    },
    mixins: [
      SplitViewMixin
    ],
    components: {
      Table,
      SplitLayout,
      NavigationLayout
    }
  }
</script>