<template>
  <span class="c__icon"/>
</template>

<script>
let cache = new Map()

export default {
  props: [
    'src',
  ],
  async mounted() {
    if (!cache.has(this.src)) {
      try {
        cache.set(this.src, fetch(this.src).then(r => r.text()));
      } catch (e) {
        cache.delete(this.src);
      }
    }
    if (cache.has(this.src)) {
      this.$el.innerHTML = await cache.get(this.src);
    }
  }
}
</script>

<style lang="scss" scoped>
  .c__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-black;
  }
</style>
