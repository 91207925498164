import Dashboard from '../../views/Dashboard'
import Settings from '../../views/Settings'
import Search from '../../views/Search'

export default [
  {
    path: '/',
    redirect: '/:lang/auth/login'
  },
  {
    path: '/:lang',
    redirect: '/:lang/auth/login'
  },
  {
    path: '/:lang/:appId',
    component: Dashboard,
    name: 'dashboard',
    meta: {
      auth: true,
    }
  },
  {
    path: '/:lang/:appId/settings',
    component: Settings,
    meta: {
      auth: true,
    }
  },
  {
    path: '/:lang/:appId/search',
    component: Search,
    meta: {
      auth: true,
    }
  }
]