import APIFactory from '../../api/factory'

export default {
  namespaced: true,
  state: {
    pages: [],
    servers: [],
    domains: [],
    providers: [],
  },
  mutations: {
    setProviders(state, providers) {
      state.providers = providers
    },
    setDomains(state, domains) {
      state.domains = domains
    },
    setServers(state, servers) {
      state.servers = servers
    },
    setPages(state, pages) {
      state.pages = pages
    },
  },
  actions: {
    loadProviders({commit}, payload) {
      return APIFactory.findProviders(payload.appId).then(res => {
        if (res && res.success) {
          commit('setProviders', res.data)
        }
        return res
      })
    },
    loadDomains({commit}, payload) {
      return APIFactory.findDomains(payload.appId).then(res => {
        if (res && res.success) {
          commit('setDomains', res.data)
        }
        return res
      })
    },
    loadServers({commit}, payload) {
      return APIFactory.findServers(payload.appId).then(res => {
        if (res && res.success) {
          commit('setServers', res.data)
        }
        return res
      })
    },
    loadPages({commit}, payload) {
      return APIFactory.findPages(payload.appId).then(res => {
        if (res && res.success) {
          commit('setPages', res.data)
        }
        return res
      })
    },
    saveProvider({commit, state, getters, dispatch}, payload) {
      return (!getters.providerById(payload.id)
        ? APIFactory.createProvider(payload.appId, payload.name, payload.url, payload.comment)
        : APIFactory.updateProvider(payload.appId, payload.id, payload.name, payload.url, payload.comment))
      .then((res) => {
        return dispatch('loadProviders', payload).then(() => res)
      })
    },
    saveDomain({commit, state, getters, dispatch}, payload) {
      return (!getters.domainById(payload.id)
        ? APIFactory.createDomain(payload.appId, payload.name, payload.customer_id, payload.provider_id, payload.comment)
        : APIFactory.updateDomain(payload.appId, payload.id, payload.name, payload.customer_id, payload.provider_id, payload.comment))
      .then((res) => {
        return dispatch('loadDomains', payload).then(() => res)
      })
    },
    saveServer({commit, state, getters, dispatch}, payload) {
      return (!getters.serverById(payload.id)
        ? APIFactory.createServer(
          payload.appId,
          payload.name,
          payload.hostname,
          payload.customer_id,
          payload.provider_id,
          payload.ipv4,
          payload.ipv6,
          payload.comment)
        : APIFactory.updateServer(payload.appId,
          payload.id,
          payload.name,
          payload.hostname,
          payload.customer_id,
          payload.provider_id,
          payload.ipv4,
          payload.ipv6,
          payload.comment))
      .then((res) => {
        return dispatch('loadServers', payload).then(() => res)
      })
    },
    savePage({commit, state, getters, dispatch}, payload) {
      return (!getters.pageById(payload.id)
        ? APIFactory.createPage(
          payload.appId,
          payload.name,
          payload.domain_id,
          payload.server_id,
          payload.customer_id,
          payload.provider_id,
          payload.url,
          payload.tag,
          payload.monitoring,
          payload.comment)
        : APIFactory.updatePage(
          payload.appId,
          payload.id,
          payload.name,
          payload.domain_id,
          payload.server_id,
          payload.customer_id,
          payload.provider_id,
          payload.url,
          payload.tag,
          payload.monitoring,
          payload.comment))
      .then((res) => {
        return dispatch('loadPages', payload).then(() => res)
      })
    },
    deleteProvider({dispatch}, payload) {
      return APIFactory.deleteProvider(payload.appId, payload.id).then(res => {
        return dispatch('loadProviders', payload).then(() => res)
      })
    },
    deleteDomain({dispatch}, payload) {
      return APIFactory.deleteDomain(payload.appId, payload.id).then(res => {
        return dispatch('loadDomains', payload).then(() => res)
      })
    },
    deleteServer({dispatch}, payload) {
      return APIFactory.deleteServer(payload.appId, payload.id).then(res => {
        return dispatch('loadServers', payload).then(() => res)
      })
    },
    deletePage({dispatch}, payload) {
      return APIFactory.deletePage(payload.appId, payload.id).then(res => {
        return dispatch('loadPages', payload).then(() => res)
      })
    },
    assignToProvider({disaptch}, payload) {
      return APIFactory.assignToProvider(
        payload.appId,
        payload.id,
        payload.reference_type,
        payload.reference_ids)
    },
    assignToDomain({dispatch}, payload) {
      return APIFactory.assignToDomain(
        payload.appId,
        payload.id,
        payload.reference_type,
        payload.reference_ids)
    },
    assignToServer({dispatch}, payload) {
      return APIFactory.assignToServer(
        payload.appId,
        payload.id,
        payload.reference_type,
        payload.reference_ids)
    }
  },
  getters: {
    pages: (state) => state.pages,
    pageById: (state) => (id) => state.pages.find(page => page.id === id),
    servers: (state) => state.servers,
    serverById: (state) => (id) => state.servers.find(server => server.id === id),
    domains: (state) => state.domains,
    domainById: (state) => (id) => state.domains.find(domain => domain.id === id),
    providers: (state) => state.providers,
    providerById: (state) => (id) => state.providers.find(provider => provider.id === id)
  }
}