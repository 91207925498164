<template>
  <main id="app">
    <router-view/>
  </main>
</template>

<script>
  export default {
    created() {
      this.updateDefaultRoute()
    },
    methods: {
      updateDefaultRoute() {
        if (!this.$route.params.lang) {
          this.$router.push('/' + this.$i18n.locale)
        }
      }
    },
    components: {
    }
  }
</script>

<style lang="scss">
  @import '@/assets/scss/app';

  ul.uk-tab {
    margin-bottom: 0;
  }
</style>
