<template>
  <div class="uk-flex uk-flex-center">
    <div class="form-wrapper">
      <form
        class="uk-form-stacked"
        @submit.prevent="submit">
        <h3 class="uk-margin-remove-bottom">{{ $t('auth.forgot') }}</h3>
        <div class="uk-margin-small">{{ $t('auth.reset_message') }}</div>
        <labeled-input
          required
          type="email"
          v-model="email"
          class="uk-width-1-1@s">
          {{ $t('generic.email') }}
        </labeled-input>
        <vk-loadable-button
          type="primary"
          html-type="submit"
          :loading="loading"
          class="uk-margin-small-top">
          {{ $t('auth.reset_password') }}
        </vk-loadable-button>
      </form>
    </div>
  </div>
</template>

<script>
  import LabeledInput from '../../components/global/inputs/LabeledInput'
  import VkLoadableButton from '../../components/global/vuikit/VkLoadableButton'

  export default {
    data() {
      return {
        email: null,
        loading: false
      }
    },
    methods: {
      submit() {
        this.loading = true
        this.$store.dispatch('auth/forgot', {
          email: this.email
        }).then(res => {
          if (res && res.success) {
            this.$toasted.success(this.$t('auth.reset_password_successful'))
            this.$router.push('/' + this.$i18n.locale + '/auth/login')
          }
        }).finally(() => this.loading = false)
      }
    },
    components: {
      VkLoadableButton,
      LabeledInput
    }
  }
</script>

<style lang="scss" scoped>
  .form-wrapper {
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    max-width: 560px;
    padding: 0 16px;
    display: flex;
    width: 100%;

    form {
      padding: 24px;
      width: calc(100% - 48px);
      background: $color-secondary;
    }
  }
</style>