<template>
  <div class="mt-1 relative user--input">
    <button
      type="button"
      @click.prevent="toggle"
      class="relative w-full bg-white border pl-3 pr-5 py-2 text-left cursor-default rounded-md border-gray-300
      focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm shadow-sm">
    <span class="flex items-center overflow-x-hidden">
      <img
        :src="selectedImage.avatar"
        class="flex-shrink-0 h-5 w-5 rounded-full mx-0.5"
        v-if="value"
        v-for="selectedImage in selectedImages"
      />
      <span
        class="ml-2"
        v-if="!multiple">
        {{ selectedValue }}
      </span>
        <template v-else>
          <template v-if="(!value || (Array.isArray(value) && value.length === 0)) && emptyValue">
          {{ emptyValue.label }}
        </template>
      </template>
    </span>
    <span class="ml-3 absolute inset-y-0 right-0 flex items-center justify-center pointer-events-none">
      <Icon
        src="images/icons/dropdown.svg"
      />
    </span>
    </button>
    <div
      v-show="open"
      class="absolute z-50 mt-1 w-full bg-white shadow-lg rounded-md">
      <ul class="max-h-56 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm rounded-md">
        <li
          :key="element.id"
          v-for="element in values"
          @click.prevent="select(element.id)"
          class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-blue-200">

          <div class="flex items-center">
            <img
              v-if="element.avatar"
              :src="element.avatar"
              class="flex-shrink-0 h-6 w-6 rounded-full">
            <span class="ml-3 block font-normal truncate">{{ element.label }}</span>
          </div>

          <span
            v-if="isElementChecked(element.id)"
            class="absolute inset-y-0 right-0 flex items-center pr-2">
            <Icon src="images/icons/check.svg" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Icon from '../../../components/global/Icon'

  export default {
    props: [
      'value',
      'values',
      'multiple',
    ],
    computed: {
      emptyValue() {
        return this.values.find(item => !item.id)
      },
      selectedImages() {
        return this.values.filter(item => {
          let singleValue = this.value
          if (!Array.isArray(singleValue)) {
            singleValue = [singleValue]
          }
          return singleValue
            && singleValue.indexOf(item.id) > -1
        })
      },
      selectedValue() {
        let singleValue = this.value
        let selectedValue = this.values.find(item => {
          if (!Array.isArray(singleValue)) {
            singleValue = [singleValue]
          }
          return singleValue && item.id === singleValue[0]
            || (!item.id && (!singleValue || singleValue.length === 0))
            || (!item.id && singleValue && !singleValue[0])
        })

        if (selectedValue) {
          return selectedValue.label
        }
      }
    },
    data() {
      return {
        open: false
      }
    },
    mounted() {
      document.addEventListener('click', this.isSameElement)
    },
    beforeDestroy() {
      document.removeEventListener('click', this.isSameElement)
    },
    methods: {
      select(elementId) {
        let values = this.value
        if (!values || !elementId || (this.multiple && !Array.isArray(values))) {
          values = []
        }

        // Is value empty? close dropdown
        if (!elementId || !this.multiple) {
          this.open = false
        }

        // Isnt already in values array
        if (elementId && values.indexOf(elementId) < 0) {
          if (this.multiple) {
            values.push(elementId)
          } else {
            values = [elementId]
          }
        } else {
          values = values.filter(item => item !== elementId)
        }
        this.$emit('input', values)
      },
      toggle() {
        this.open = !this.open
      },
      isElementChecked(elementId) {
        return this.value && this.value.indexOf(elementId) > -1
      },
      isSameElement($event) {
        if (!this.$el.contains($event.target)) {
          this.open = false
        }
      }
    },
    components: {
      Icon
    }
  }
</script>