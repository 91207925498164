<template>
  <div class="tasks-wrapper">
    <header class="flex justify-between">
        <h1 class="text-lg leading-6 font-semibold text-gray-900">
          <div>{{ $t('nav.tasks' )}}</div>
        </h1>
        <button type="button" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                @click="newTask"
                :tooltip="$t('projects.new_task')"
        >
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
        </button>
    </header>

    <div class="sections">
      <div
        class="section"
        v-for="section in status">
        <div class="section-headline">{{ $t('projects.task_status_' + section) }}</div>
        <div class="section-content">
          <TaskListEntry
            :key="index"
            :id="task.id"
            :name="task.name"
            @completeTask="completeTask"
            v-for="(task, index) in getTasksByStatus(section)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TaskListEntry from '../projects/TaskListEntry'
  import VkIconButton from '../../components/global/vuikit/VkIconButton'

  export default {
    props: [
      'tasks'
    ],
    data() {
      return {
        status: [
          'open',
          'waiting',
          'in_progress',
          'waiting_for_response',
          'testing',
          'finished'
        ],
      }
    },
    methods: {
      getTasksByStatus(status) {
        if (this.tasks) {
          return this.tasks.filter(task => task.status === status)
        }
      },
      completeTask(id) {
        let completableTask = this.tasks.find(task => task.id === id)
        if (completableTask) {
          this.$store.dispatch('project/saveTask', {
            ...completableTask,
            status: 'finished',
            appId: this.$route.params.appId
          }).then(res => {
            if (res && res.success) {
              this.$toasted.success(this.$t('projects.task_completed'))
            }
          })
        }
      },
      newTask() {
        this.$router.push({query: { taskId: 'new' }})
      }
    },
    components: {
      TaskListEntry,
      VkIconButton
    }
  }
</script>

<style lang="scss" scoped>
  .tasks-wrapper {
    padding: 24px;
    height: calc(100% - 48px);

    .headline {
      font-size: 20px;
      font-weight: 200;
      display: flex;
      justify-content: space-between;
    }

    .sections {
      width: 100%;
      overflow-y: auto;
      padding-right: 20px;
      height: calc(100% - 6px);

      .section {
        margin: 24px 0;

        .section-headline { font-weight: 200; }
        .section-content { margin-top: 8px; }
      }
    }
  }
</style>