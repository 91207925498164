<template>
  <div class="w-full min-h-72px flex mt-8 p-2 border-box bg-blue-50 justify-between rounded">
    <div class="avatar flex-grow-0 px-4 py-2 flex flex-shrink-0 items-start justify-center">
      <img
        alt="Avatar"
        :src='avatar'
        class="max-w-thumbnail-sm max-h-thumbnail-sm rounded-full"
      />
    </div>

    <div class="w-full p-2">
      <div class="flex text-xs mb-1 justify-between">
        <div class="font-bold">{{ creator ? creator : $t('app.removed_user') }}</div>

        <div class="opacity-30 text-1xs">{{ created_at | datetime }} {{ $t('generic.clock') }}</div>
      </div>

      <div
        class="comment"
        v-html="content"
      />

      <div class="flex justify-end">
        <ConfirmModal
          @confirm="deleteComment"
          :title="$t('projects.confirm_delete_comment_title')"
          :message="$t('projects.confirm_delete_comment_message')">
          <div class="cursor-pointer text-xs hover:underline">{{ $t('generic.delete') }}</div>
        </ConfirmModal>
      </div>
    </div>
  </div>
</template>

<script>
  import ConfirmModal from "@/components/global/modals/ConfirmModal";
  export default {
    components: {ConfirmModal},
    props: [
      'id',
      'avatar',
      'content',
      'creator',
      'created_at',
    ],
    methods: {
      deleteComment() {
        this.loading = true
        this.$store.dispatch('project/deleteComment', {
          appId: this.$route.params.appId,
          id: this.id,
        }).then(res => {
          if (res && res.success) {
            this.$toasted.success(this.$t('projects.comment_deleted'))
          }
        })
      }
    }
  }
</script>
