<template>
  <div
    class="task-wrapper"
    @click.prevent="selectTask(id)">
    <div class="column">
      <div
        class="icon"
        @click.prevent="completeTask">
        <slot name="icon" />
      </div>
      <div class="name">{{ name }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'id',
      'name',
    ],
    methods: {
      selectTask(taskId) {
        this.$router.push({
          query: {
            taskId
          }
        }).catch(err => {})
      },
      completeTask() {
        this.$emit('completeTask', this.id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  $task-height: 30px;

  .task-wrapper {
    width: 100%;
    display: flex;
    font-weight: 200;
    margin-bottom: 4px;
    align-items: center;
    border-radius: 17px;
    height: $task-height;
    background: $color-white;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
      background: darken($color-white, 5%);
    }

    .column {
      display: flex;
      align-items: center;
    }

    .icon {
      border-radius: 50%;
      width: $task-height;
      height: $task-height;
      border: 1px dashed $color-gray-03;

      &:hover {
        background: $color-gray-03;
        border: 1px solid $color-gray-03;
      }
    }

    .name {
      margin-left: 8px;
    }
  }
</style>