<template>
  <div class="flex w-full h-screen shadow-xl">
    <div class="w-full z-10 py-4 px-8 h-full bg-secondary shadow max-w-xs flex flex-col justify-between">
      <div class="w-full overflow-y-auto h-5/6">
        <!-- Logo -->
        <Sitetape class="my-20 flex justify-center"/>

        <!-- Search -->
        <div class="px-1">
          <label
            for="search"
            class="sr-only">
            Suche
          </label>

          <input
            type="text"
            name="search"
            id="search"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Suche..."
            @input="search"
          >
        </div>

        <!-- menu -->
        <div class="mt-5 flex-grow flex flex-col">
          <nav class="flex-1 px-1 bg-white space-y-1">
            <a
              v-for="(entry, index) in entries"
              :key="index"
              :href="entry.link"
              class="rounded-md text-sm px-2 py-2 group flex items-center"
              :class="{
                'bg-tertiary text-white hover:text-white': isActive(entry.link),
                'text-gray-400 hover:bg-gray-50 hover:text-gray-900': !isActive(entry.link)
              }"
            >
              <!-- TODO:: Replace Icons -->
              <vk-icon
                :class="{
                  'text-white mr-3 h-6 w-6': isActive(entry.link),
                  'text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6': !isActive(entry.link)
                }"
                :icon="entry.icon"
              />
              {{ $t(entry.label) }}
            </a>
          </nav>
        </div>
      </div>

      <hr class="mt-6 mb-6"/>

      <!-- User profil -->
      <div class="flex">
        <div class="w-full p-4 flex rounded-md bg-primary">
          <img
            :src="avatar"
            class="w-12 h-12 rounded-full"
          />
          <div class="w-full flex font-bold items-center ml-6 text-tertiary select-none">{{ $store.getters['auth/fullname'] }}</div>
        </div>
      </div>
    </div>

    <!-- page content -->
    <div
      class="flex-grow overflow-y-auto"
      :class="{'p-7': padding }">
      <slot/>
    </div>
  </div>
</template>

<script>
  import Sitetape from '../global/Sitetape'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'padding'
    ],
    computed: {
      ...mapGetters({
        avatar: 'auth/avatar'
      })
    },
    data() {
      return {
        entries: [
          {
            icon: 'thumbnails',
            label: 'nav.dashboard',
            link: this.prepare(),
          },
          {
            icon: 'calendar',
            label: 'nav.projects',
            link: this.prepare('/projects'),
          },
          {
            icon: 'check',
            label: 'nav.tasks',
            link: this.prepare('/tasks'),
          },
          {
            icon: 'users',
            label: 'nav.customers',
            link: this.prepare('/customers'),
          },
          {
            icon: 'list',
            label: 'nav.providers',
            link: this.prepare('/providers'),
          },
          {
            icon: 'world',
            label: 'nav.domains',
            link: this.prepare('/domains'),
          },
          {
            icon: 'server',
            label: 'nav.servers',
            link: this.prepare('/servers'),
          },
          {
            icon: 'link',
            label: 'nav.pages',
            link: this.prepare('/pages'),
          },
          {
            icon: 'cog',
            label: 'nav.settings',
            link: this.prepare('/settings'),
          },
          {
            icon: 'sign-out',
            label: 'nav.logout',
            link: '#/' + this.$i18n.locale + '/auth/logout',
          },
        ]
      }
    },
    methods: {
      prepare(url) {
        return (this.$router.mode === 'hash' ? '#' : '')
          + '/' + this.$i18n.locale + '/' + this.$route.params.appId
          + (url ? url : '')
      },
      isActive(link) {
        return (this.$router.mode === 'hash' ? '#' : '') + this.$route.path === link
      },
      /**
       * Debounces the event and fires a search against the api
       * @param $event
       */
      search($event) {
        this.throttle(() => {
          let targetURL = '/' + this.$i18n.locale + '/' + this.$route.params.appId + '/search?keyword=' + $event.target.value
          if (targetURL !== this.$route.fullPath) {
            this.$router.push(targetURL)
          }
        }, 500)
      },
      throttle(func, limit) {
        if (!this.timeout) {
          this.timeout = setTimeout(func, limit)
        } else {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(func, limit)
        }
      },
    },
    components: {
      Sitetape
    }
  }
</script>
