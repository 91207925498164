<template>
  <vk-button
    :type="type"
    :html-type="htmlType"
    :disabled="disabled || loading">
    <template v-if="loading">
      <vk-spinner ratio="0.6" />
    </template>
    <slot v-else />
  </vk-button>
</template>

<script>
  export default {
    props: [
      'type',
      'primary',
      'loading',
      'disabled',
      'htmlType',
    ],
  }
</script>