<template>
  <div class="w-full h-full flex justify-center">
    <div class="flex-grow-0 h-full w-8/12 px-2">
      <slot />
    </div>

    <div class="flex-grow-0 h-full w-8/12 px-2" v-if="displaySecondColumn">
      <component :is="dynamicComponent" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["routeParam", "queryComponent", "routeComponent", "query-param-map"],
  computed: {
    displaySecondColumn() {
      return (
        Object.keys(this.queryParamMap).filter(
          (item) => !!this.$route.query[item]
        ).length > 0 || this.$route.params[this.routeParam]
      );
    },
    isRouteComponent() {
      return (
        this.$route.params[this.routeParam] &&
        Object.keys(this.queryParamMap).filter(
          (item) => !!this.$route.query[item]
        ).length === 0
      );
    },
    dynamicComponent() {
      let queryParam = Object.keys(this.queryParamMap).filter(
        (item) => !!this.$route.query[item]
      )[0];
      return this.isRouteComponent
        ? this.routeComponent
        : this.queryParamMap[queryParam];
    },
  },
};
</script>