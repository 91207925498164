<template>
  <NavigationLayout :padding="true">
      <vk-card>
        <vk-card-title>Suche nach - {{ searchParam }} -</vk-card-title>
      </vk-card>

      <!-- Table here -->
      <Table
        :key="index"
        class="uk-margin-top"
        :data="searchResult[section.id]"
        :config="createTableConfig(section)"
        v-for="(section, index) in sections"
        @row-selected="selectRow(section, $event)"
        v-if="searchResult[section.id] && searchResult[section.id].length > 0">

      </Table>
  </NavigationLayout>
</template>

<script>
  import NavigationLayout from '../components/layout/NavigationLayout'
  import TableHelper from '../helpers/table-headers'
  import Table from '../components/global/Table'

  export default {
    computed: {
      searchParam() {
        return this.$route.query.keyword
      },
    },
    data() {
      return {
        searchResult: {},
        sections: [
          {
            id: 'projects',
            label: 'nav.projects',
            message: 'projects.no_project_found',
          },
          {
            id: 'tasks',
            label: 'nav.tasks',
            message: 'projects.no_task_found'
          },
          {
            id: 'customers',
            label: 'nav.customers',
            message: 'customers.no_customers_found'
          },
          {
            id: 'domains',
            label: 'nav.domains',
            message: 'web.no_domains_found'
          },
          {
            id: 'providers',
            label: 'nav.providers',
            message: 'web.no_providers_found'
          },
          {
            id: 'servers',
            label: 'nav.servers',
            message: 'web.no_servers_found'
          },
          {
            id: 'pages',
            label: 'nav.pages',
            message: 'web.no_pages_found'
          },
        ],
      }
    },
    methods: {
      createTableConfig(header) {
        return {
          search: true,
          pagination: true,
          title: this.$t(header.label),
          headers: TableHelper[header.id],
          emptyMessage: this.$t(header.message),
        }
      },
      async selectRow(section, $event, appendURL) {
        let newRoute = '/' + this.$i18n.locale
          + '/' + this.$route.params.appId
          + '/' + section.id
          + '/' + $event.id
          + (appendURL ? appendURL : '')
        await this.$router.push(newRoute)
      },
      search() {
        this.loading = true
        this.$store.dispatch('app/search', {
          appId: this.$route.params.appId,
          keyword: this.searchParam
        })
        .then(res => {
          if (res && res.success) {
            this.searchResult = res.data
          }
        })
        .finally(() => this.loading = false)
      }
    },
    watch: {
      searchParam: {
        immediate: true,
        handler() {
          this.search()
        }
      }
    },
    components: {
      NavigationLayout,
      Table
    }
  }
</script>