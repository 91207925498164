<template>
  <div class="text-3xl">
    <div class="px-4 py-5 sm:px-6 flex select-none justify-between text-gray-800">
      <div>{{ title }}</div>

      <div class="flex">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [ 'title' ]
  }
</script>