<template>
  <div class="uk-flex uk-flex-center">
    <div class="form-wrapper">
      <form
        @submit.prevent="submit"
        class="uk-form-stacked">
        <h3>Kostenlos registrieren</h3>
        <vk-grid>
          <labeled-input
            required
            v-model="signup.lastname"
            class="uk-width-1-2@s">
            {{ $t('generic.lastname') }}
          </labeled-input>

          <labeled-input
            required
            v-model="signup.firstname"
            class="uk-width-1-2@s">
            {{ $t('generic.firstname') }}
          </labeled-input>

          <labeled-input
            required
            type="email"
            v-model="signup.email"
            class="uk-width-1-1@s uk-margin-small">
            {{ $t('generic.email') }}
          </labeled-input>

          <labeled-input
            required
            type="password"
            v-model="signup.password"
            class="uk-width-1-2@s uk-margin-small">
            {{ $t('generic.password') }}
          </labeled-input>

          <labeled-input
            required
            type="password"
            v-model="signup.password_confirm"
            class="uk-width-1-2@s uk-margin-small">
            {{ $t('generic.password_confirm') }}
          </labeled-input>

          <div class="uk-width-1-1@s uk-margin-small">
            <label>
              <input
                required
                type="checkbox"
                class="uk-checkbox"
                v-model="signup.accept_terms"
              />
              {{ $t('auth.accept_terms') }}
            </label>
          </div>
        </vk-grid>

        <div class="uk-flex uk-flex-between uk-margin-top">
          <vk-loadable-button
            type="primary"
            html-type="submit"
            :loading="loading">
            {{ $t('auth.register') }}
          </vk-loadable-button>
          <router-link :to="'/' + $i18n.locale + '/auth/login'">
            <vk-button type="link">{{ $t('auth.registered_yet') }}</vk-button>
          </router-link>
        </div>
      </form>

      <div class="uk-margin-medium uk-flex uk-flex-center">
        <Sitetape />
      </div>
    </div>
  </div>
</template>

<script>
  import Sitetape from '../../components/global/Sitetape'
  import LabeledInput from '../../components/global/inputs/LabeledInput'
  import VkLoadableButton from '../../components/global/vuikit/VkLoadableButton'

  export default {
    data() {
      return {
        signup: {},
        loading: false,
      }
    },
    methods: {
      submit() {
        // Check if the password confirm is wrong/right
        if (this.signup.password !== this.signup.password_confirm) {
          this.$toasted.error(this.$t('auth.password_confirm_wrong'))
          return
        }

        // Fire API Call
        this.loading = true
        this.$store.dispatch('auth/signup', this.signup).then(res => {
          if (res && res.success) {
            /*
            this.$gtm.trackEvent({
              event: 'conversion',
              category: "Conversion",
              action: "click"
            });
            */

            this.$toasted.success(this.$t('auth.signup_successful'))
            this.$router.push({ name: 'login' })
          }
        }).finally(() => this.loading = false)
      }
    },
    components: {
      VkLoadableButton,
      LabeledInput,
      Sitetape
    }
  }
</script>

<style lang="scss" scoped>
.form-wrapper {
  justify-content: center;
  flex-direction: column;
  max-width: 560px;
  padding: 0 16px;
  display: flex;
  height: 100vh;
  width: 100%;

  form {
    padding: 24px;
    width: calc(100% - 48px);
    background: $color-secondary;
  }
}
</style>
