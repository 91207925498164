import ProjectView from '../../views/projects/ProjectView'
import Projects from '../../views/projects/Projects'
import Tasks from '../../views/projects/Tasks'

export default [
    {
        path: '/:lang/:appId/projects/:projectId?',
        component: Projects,
        meta: {
            auth: true,
        }
    },
    {
        path: '/:lang/:appId/projects/:projectId?/view',
        component: ProjectView,
        meta: {
            auth: true,
        }
    },
    {
        path: '/:lang/:appId/tasks/:taskId?',
        component: Tasks,
        meta: {
            auth: true,
        }
    }
]