import {v4 as UUIDv4} from 'uuid'

export default {
  computed: {
    /**
     * Retrieves the model for the detail page
     * @returns {*}
     */
    referenceModel() {
      if (this.vuexModelFetch && this.routeParam) {
        if (this.$route.query[this.routeParam]) {
          return this.$store.getters[this.vuexModelFetch](this.$route.query[this.routeParam])
        }
        return this.$store.getters[this.vuexModelFetch](this.$route.params[this.routeParam])
      }
    },
    /**
     * Retrieves a boolean value, wheater the route is a 'new'-object-route
     * @returns {boolean}
     */
    isCreateMode() {
      return (this.$route.params && this.$route.params[this.routeParam] === 'new')
        || (this.$route.query && this.$route.query[this.routeParam] === 'new')
    },
    /**
     *  Checks if this view is called as 'query param' or as 'route param'
     */
    isViewCalledByQueryParam() {
      return !!this.$route.query[this.routeParam]
    },
    /**
     *  Returns the tab icon (it is locked if the object is created)
     */
    tabIcon() {
      return this.isCreateMode
        ? 'lock'
        : ''
    }
  },
  data() {
    return {
      modelCreatedText: null,
      modelUpdatedText: null,
      modelDeletedText: null,
      vuexModelFetch: null,
      routeParam: null,
      loading: false,
      model: {
        id: UUIDv4(),
      },
    }
  },
  methods: {
    /**
     * Set this mixin up for generic usage
     * @param routeParam
     * @param vuexModelFetch
     * @param modelCreatedText
     * @param modelUpdatedText
     * @param modelDeletedText
     */
    setup(routeParam, vuexModelFetch, modelCreatedText, modelUpdatedText, modelDeletedText) {
      this.routeParam = routeParam
      this.vuexModelFetch = vuexModelFetch
      this.modelCreatedText = modelCreatedText
      this.modelUpdatedText = modelUpdatedText
      this.modelDeletedText = modelDeletedText
      this.initModel(this.referenceModel)
    },
    /**
     * Displays title of detail page
     * @param fallbackMessage
     * @param property
     * @returns {VueI18n.TranslateResult|*|null}
     */
    title(fallbackMessage, property) {
      let fetchProperty = property || 'name'
      return this.isCreateMode
        ? this.$t(fallbackMessage)
        : this.model
          ? this.model[fetchProperty]
          : null
    },
    /**
     * Stores the model in the vuex store
     * @param vuexAction
     * @param mergableData
     * @returns {Promise<any>}
     */
    saveModel(vuexAction, mergableData = {}) {
      // Append Model.id and Model.appId
      // Check if routeParam or queryParam id is set
      let id = this.$route.query[this.routeParam]
        ? this.$route.query[this.routeParam]
        : this.$route.params[this.routeParam]
      if (id !== 'new') {
        this.model.id = id
      }
      this.model.appId = this.$route.params.appId
      this.loading = true

      return this.$store.dispatch(vuexAction, Object.assign({}, this.model, mergableData))
        .then((res) => {
          if (res && res.success) {
            // Set Id to the model after creation
            if (this.isCreateMode) {
              this.model.id = res.data.id
            }

            // Fire toast
            let translationText = this.isCreateMode
              ? this.modelCreatedText
              : this.modelUpdatedText
            this.$toasted.success(this.$t(translationText))
          }
        })
        .finally(() => this.loading = false)
    },
    deleteModel(vuexAction) {
      let payload = {
        id: this.$route.query[this.routeParam] || this.$route.params[this.routeParam],
        appId: this.$route.params.appId
      }
      return this.$store.dispatch(vuexAction, payload).then((res) => {
        if (res && res.success) {
          this.$toasted.success(this.$t(this.modelDeletedText))
        }
        return res
      }).finally(() => this.loading = false)
    },
    /**
     * Prefetches data initially on based actions
     * @param vuexActions
     */
    prefetchData(vuexActions = []) {
      this.loading = true
      let payload = { appId: this.$route.params.appId }
      return Promise
        .all(vuexActions.map(action => this.$store.dispatch(action, payload)))
        .finally(() => this.loading = false)
    },
    /**
     * Initializes this mixin to set it to the model
     * @param referenceModel
     */
    initModel(referenceModel) {
      this.model = referenceModel
        ? JSON.parse(JSON.stringify(referenceModel))
        : {id: UUIDv4()}
    },
    /**
     * Returns an object with given data which is used for the
     * tooltip from vuikit
     * @param translationText
     */
    tooltip(translationText) {
      return {
        title: this.$t(translationText),
        delay: this.$store.getters['app/tooltipDelay']
      }
    },
    /**
     * Redirects to given url
     */
    redirect(url) {
      return this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + url)
    }
  },
  watch: {
    /**
     *  Watches the route and if it changes - the new model will be created
     */
    '$route'() {
      this.initModel(this.referenceModel)
    },
  }
}