<template>
  <NavigationLayout :padding="false">

    <div class="w-full bg-white p-5 md:flex md:items-center md:justify-between">
      <div class="flex-1 min-w-0">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          {{ $t('nav.dashboard') }}
        </h2>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4">
        <a
            type="button"
            :href="`#/${$i18n.locale}/${$route.params.appId}/projects/new`"
            :tooltip="tooltip('tooltips.create_project')"
            class="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white"
        >
          <Icon
              src="images/icons/projects.svg"
              style="color: #fff;"
          />
        </a>
        <a
            type="button"
            icon="check"
            class="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white"
            :href="`#/${$i18n.locale}/${$route.params.appId}/tasks/new`"
            :tooltip="tooltip('tooltips.create_task')"
        >
          <Icon
              src="images/icons/tasks.svg"
              style="color: #fff;"
          />
        </a>
        <a
            type="button"
            icon="users"
            class="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white"
            :href="`#/${$i18n.locale}/${$route.params.appId}/customers/new`"
            :tooltip="tooltip('tooltips.create_customer')"
        >
          <Icon
              src="images/icons/customers.svg"
              style="color: #fff;"
          />        </a>
        <a
            type="button"
            icon="close"
            class="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white"
            :href="`#/${$i18n.locale}/${$route.params.appId}/providers/new`"
            :tooltip="tooltip('tooltips.create_provider')"
        >
          <Icon
              src="images/icons/providers.svg"
              style="color: #fff;"
          />        </a>
        <a
            type="button"
            icon="close"
            class="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white"
            :href="`#/${$i18n.locale}/${$route.params.appId}/domains/new`"
            :tooltip="tooltip('tooltips.create_domain')"
        >
          <Icon
              src="images/icons/domains.svg"
              style="color: #fff;"
          />        </a>
        <a
            type="button"
            icon="server"
            class="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white"
            :href="`#/${$i18n.locale}/${$route.params.appId}/servers/new`"
            :tooltip="tooltip('tooltips.create_server')"
        >
          <Icon
              src="images/icons/servers.svg"
              style="color: #fff;"
          />        </a>
        <a
            type="button"
            icon="world"
            class="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white"
            :href="`#/${$i18n.locale}/${$route.params.appId}/pages/new`"
            :tooltip="tooltip('tooltips.create_page')"
        >
          <Icon
              src="images/icons/pages.svg"
              style="color: #fff;"
          />        </a>
      </div>
    </div>


    <vk-container>
      <vk-grid class="uk-child-width-expand@s mt-10">
        <div>
          <div class="actions-headline"></div>
          <div class="actions-buttons">

          </div>
        </div>
      </vk-grid>

      <vk-grid class="uk-child-width-expand@m">
        <div>
          <Table
            :data="tasks"
            :config="createTableConfig(taskTableConfig)"
            @row-selected="onRowClick('tasks', $event)"
          />
        </div>
        <div>
          <Table
            :data="pages"
            :config="createTableConfig(pagesTableConfig)"
            @row-selected="onRowClick('pages', $event)"
          />
        </div>
      </vk-grid>
    </vk-container>
  </NavigationLayout>
</template>

<script>
  import NavigationLayout from '../components/layout/NavigationLayout'
  import VkContainer from '../components/global/vuikit/VkContainer'
  import IconButton from '../components/global/tailwind/IconButton'
  import TableHelper from '../helpers/table-headers'
  import Table from '../components/global/Table'
  import {mapGetters} from 'vuex'
  import Icon from '../components/global/Icon'

  export default {
    computed: {
      ...mapGetters({
        tasks: 'project/tasks',
        pages: 'web/pages'
      })
    },
    data() {
      return {
        loading: false,
        taskTableConfig: {
          id: 'tasks',
          label: 'nav.tasks',
          message: 'projects.no_task_found'
        },
        pagesTableConfig: {
          id: 'pages',
          label: 'nav.pages',
          message: 'web.no_pages_found',
        },
      }
    },
    created() {
      this.loading = true
      let payload = { appId: this.$route.params.appId }
      Promise.all([
        this.$store.dispatch('project/loadTasks', payload),
        this.$store.dispatch('web/loadPages', payload)
      ]).finally(() => this.loading = false)
    },
    methods: {
      onRowClick(section, $event) {
        switch (section) {
          case 'pages':
            this.iconClick('/pages/' + $event.id)
            break
          case 'tasks':
            this.iconClick('/tasks/' + $event.id)
            break
        }
      },
      iconClick(route) {
        this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + route)
      },
      tooltip(translatedText) {
        return {
          title: this.$t(translatedText),
          delay: this.$store.getters['app/tooltipDelay']
        }
      },
      createTableConfig(header) {
        return {
          slim: true,
          search: false,
          pagination: true,
          title: this.$t(header.label),
          headers: TableHelper[header.id],
          emptyMessage: this.$t(header.message),
        }
      }
    },
    components: {
      NavigationLayout,
      IconButton,
      VkContainer,
      Table,
      Icon
    }
  }
</script>

<style lang="scss" scoped>
  .actions-headline {
    font-size: 14px;
    font-weight: 500;
  }

  .actions-buttons {
    display: flex;
    //margin-top: 16px;
  }
</style>