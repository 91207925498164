import TableHeaderHelper from '../helpers/table-headers'

export default {
  computed: {
    /**
     * Returns the url of
     * @returns {string}
     */
    sectionBaseURL() {
      return '/' + this.$i18n.locale + '/' + this.$route.params.appId + '/' + this.section
    },
    /**
     * Returns a link to the section which creates a new object of the given section
     * @returns {string}
     */
    createNewObjectLink() {
      return (this.$router.mode === 'hash' ? '#' : '') + this.sectionBaseURL + '/new'
    }
  },
  data() {
    return {
      headers: [],
      section: null,
      loading: false,
      components: {
        routeComponent: null,
      },
      routeParam: null,
      queryParamMap: {},
    }
  },
  methods: {
    /**
     * Initializes the view with given credentials
     * @param section
     * @param routeParam
     * @param routeComponent
     * @param queryParamMap
     */
    setup(section, routeParam, routeComponent, queryParamMap) {
      this.section = section
      this.routeParam = routeParam
      this.queryParamMap = queryParamMap || {}
      this.components.routeComponent = routeComponent
    },
    /**
     * Select a row and redirect the router
     * @param $evt - Holds
     */
    async rowSelected($evt) {
      let newRoute = $evt ? this.sectionBaseURL + '/' + $evt.id : this.sectionBaseURL
      if (newRoute !== this.$route.path) {
        await this.$router.push(newRoute)
      }
    },
    /**
     * Creates a brand new table config
     * @param title
     * @param emptyMessage
     * @param headers
     */
    createTableConfig(title, emptyMessage, headers) {
      // Lookup table headers
      let mergeableHeaders = []
      .concat(TableHeaderHelper[this.section])
      .concat(headers || [])

      return {
        search: true,
        pagination: true,
        title: this.$t(title),
        headers: mergeableHeaders,
        emptyMessage: this.$t(emptyMessage),
      }
    }
  }
}