export default {
  'projects': [
    {id: 'name', label: 'Name'},
    {id: 'comment', label: 'Notiz'}
  ],
  'tasks': [
    { id: 'name', label: 'Name' },
    { id: 'status', label: 'Status', filter: 'taskStatus', class:'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium' },
  ],
  'customers': [
    {id: 'name', label: 'Name'},
    {id: 'phone', label: 'Telefon', class:'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'}
  ],
  'providers': [
    {id: 'name', label: 'Name'},
    {id: 'url', label: 'URL', class:'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'}
  ],
  'domains': [
    { id: 'name', label: 'Name' },
  ],
  'servers': [
    { id: 'name', label: 'Name' },
    { id: 'hostname', label: 'Hostname', class:'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'},
    { id: 'ipv4', label: 'IPV4' , class: 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800'},
    // { id: 'ipv6', label: 'IPV6' }
  ],
  'pages': [
    { id: 'name', label: 'Seite' },
    { id: 'url', label: 'URL', class: 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'},
    { id: 'status', label: 'Status', class:'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium'},
    { id: 'tag', label: 'Tags', class: 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800' }
  ]
}