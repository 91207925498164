import Vue from 'vue'
import Vuex from 'vuex'
import AppStore from './modules/app'
import WebStore from './modules/web'
import AuthStore from './modules/auth'
import ProjectStore from './modules/projects'
import CustomerStore from './modules/customer'
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    customer: CustomerStore,
    project: ProjectStore,
    auth: AuthStore,
    app: AppStore,
    web: WebStore,
  }
})
