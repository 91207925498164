<template>
  <div
      class="px-12"
    :class="{
      'uk-container': true,
      'uk-container-small': size === 'small',
      'uk-container-medium': size === 'medium'
    }">
    <slot />
  </div>
</template>

<script>
  export default {
    props: [
      'size'
    ]
  }
</script>

<style lang="scss" scoped>
  .uk-container-medium {
    max-width: 960px;
  }
</style>