<template>
  <Detail
    :title="title('customers.new_customer')"
    @submit="submit">

    <template slot="header-controls">
      <button
          icon="check"
          type="submit"
          class="w-8 h-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
      </button>
      <ConfirmModal
        @confirm="deleteCustomer"
        :title="$t('customers.confirm_delete_customer_title')"
        :message="$t('customers.confirm_delete_customer_message')">
        <button
            icon="trash"
            type="button"
            v-if="!isCreateMode"
            class="uk-margin-small-left"
            :tooltip="tooltip('generic.delete')"
        >
          <svg class="w-6 h-6" fill="none" stroke="lightgray" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
        </button>
      </ConfirmModal>
    </template>

    <vk-tabs>
      <vk-tabs-item :title="$t('generic.general')">
        <vk-grid class="uk-margin-top">
          <labeled-input
            required
            v-model="model.name"
            class="uk-width-1-1@s">
            {{ $t('generic.name') }}
          </labeled-input>

          <labeled-input
            v-model="model.phone"
            class="uk-width-1-1@s uk-margin-small">
            {{ $t('generic.phone') }}
          </labeled-input>

          <labeled-input
            type="textarea"
            v-model="model.comment"
            class="uk-width-1-1@s uk-margin-small">
            {{ $t('generic.note') }}
          </labeled-input>
        </vk-grid>
      </vk-tabs-item>

      <!-- Domains -->
      <vk-tabs-item
        :icon="tabIcon"
        :disabled="isCreateMode"
        :title="$t('nav.domains')"
        class="pt-5"
      >
        <Domains
          query-param="domainId"
          parent-type="customer"
          :foreign-values="domainValues"
        />
      </vk-tabs-item>
      <!-- Seiten -->
      <vk-tabs-item
        :icon="tabIcon"
        :disabled="isCreateMode"
        :title="$t('nav.pages')"
        class="pt-5"
      >
        <Pages
          query-param="pageId"
          :foreign-values="pageValues"
        />
      </vk-tabs-item>
      <!-- Server -->
      <vk-tabs-item
        :icon="tabIcon"
        :disabled="isCreateMode"
        :title="$t('nav.servers')"
        class="pt-5"
      >
        <Servers
          query-param="serverId"
          :foreign-values="serverValues"
        />
      </vk-tabs-item>
    </vk-tabs>
  </Detail>
</template>

<script>
  import {mapGetters} from 'vuex'
  import DetailMixin from '../../mixins/detail'
  import Detail from '../../components/layout/details/Detail'
  import Pages from '../../components/layout/details/references/Pages'
  import VkIconButton from '../../components/global/vuikit/VkIconButton'
  import Servers from '../../components/layout/details/references/Servers'
  import Domains from '../../components/layout/details/references/Domains'
  import LabeledInput from '../../components/global/inputs/LabeledInput'
  import ConfirmModal from '../../components/global/modals/ConfirmModal'

  export default {
    computed: {
      ...mapGetters({
        servers: 'web/servers',
        domains: 'web/domains',
        pages: 'web/pages',
      }),
      pageValues() {
        return this.pages.filter(page => page.customer_id === this.$route.params[this.routeParam]
          || page.customer_id === this.$route.query[this.routeParam])
      },
      serverValues() {
        return this.servers.filter(server => server.customer_id === this.$route.params[this.routeParam]
          || server.customer_id === this.$route.query[this.routeParam])
      },
      domainValues() {
        return this.domains.filter(domain => domain.customer_id === this.$route.params[this.routeParam]
          || domain.customer_id === this.$route.query[this.routeParam])
      }
    },
    created() {
      this.setup(
        'customerId',
        'customer/customerById',
        'customers.customer_created',
        'customers.customer_updated',
        'customers.customer_deleted')

      // Loading referencing models
      this.loading = true
      let payload = { appId: this.$route.params.appId }
      Promise.all([
        this.$store.dispatch('web/loadServers', payload),
        this.$store.dispatch('web/loadDomains', payload),
        this.$store.dispatch('web/loadPages', payload)
      ]).finally(() => this.loading = false)
    },
    methods: {
      submit() {
        return this.saveModel('customer/saveCustomer', ).then((res) => {
          if (this.isCreateMode) {
            this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/customers/' + this.model.id)
          }
        })
      },
      deleteCustomer() {
        return this.deleteModel('customer/deleteCustomer').then((res) => {
          if (res && res.success) {
            this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/customers')
          }
        })
      }
    },
    mixins: [
      DetailMixin
    ],
    components: {
      ConfirmModal,
      VkIconButton,
      LabeledInput,
      Servers,
      Domains,
      Detail,
      Pages
    }
  }
</script>