import Vue from 'vue'
import VueRouter from 'vue-router'

import WebRoutes from './routes/web'
import AuthRoutes from './routes/auth'
import GenericRoutes from './routes/generic'
import ProjectRoutes from './routes/projects'
import CustomerRoutes from './routes/customers'

Vue.use(VueRouter)

const routes = []
    .concat(AuthRoutes)
    .concat(GenericRoutes)
    .concat(WebRoutes)
    .concat(ProjectRoutes)
    .concat(CustomerRoutes)

const router = new VueRouter({
  routes
})

export default router
