import APIFactory from '../../api/factory'

export default {
  namespaced: true,
  state: {
    customers: [],
  },
  mutations: {
    setCustomers(state, customers) {
      state.customers = customers
    },
  },
  actions: {
    loadCustomers({commit}, payload) {
      return APIFactory.findCustomers(payload.appId).then(res => {
        if (res && res.success) {
          commit('setCustomers', res.data)
        }
        return res
      })
    },
    saveCustomer({commit, state, getters, dispatch}, payload) {
      return (!getters.customerById(payload.id)
        ? APIFactory.createCustomer(payload.appId, payload.name, payload.phone, payload.comment)
        : APIFactory.updateCustomer(payload.appId, payload.id, payload.name, payload.phone, payload.comment))
      .then((res) => {
        return dispatch('loadCustomers', payload).then(() => res)
      })
    },
    deleteCustomer({dispatch}, payload) {
      return APIFactory.deleteCustomer(payload.appId, payload.id).then(res => {
        dispatch('loadCustomers', payload)
        return res
      })
    },
    assignToCustomer({dispatch}, payload) {
      return APIFactory.assignToCustomer(
        payload.appId,
        payload.id,
        payload.reference_type,
        payload.reference_ids)
    }
  },
  getters: {
    customers: (state) => state.customers,
    customerById: (state) => (id) => state.customers.find(customer => customer.id === id)
  }
}