import i18n from '../locales'
import moment from 'moment'

export default {
  install(Vue, options) {
    Vue.filter('taskStatus', (value) => {
      return i18n.te('projects.task_status_' + value)
        ? i18n.t('projects.task_status_' + value)
        : value
    })

    Vue.filter('datetime', (value) => {
      if (value) {
        return moment(value).format('DD.MM.YYYY HH:mm')
      }
    })
  }
}