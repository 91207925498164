<template>
  <button
      class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      :type="type"
      :html-type="htmlType"
      :disabled="disabled || loading">
    <template v-if="loading">
      <!-- TODO:: replace Spinner -->
      <vk-spinner ratio="0.6" />
    </template>
    <slot v-else />
  </button>
</template>

<script>
export default {
  props: [
    'type',
    'primary',
    'loading',
    'disabled',
    'htmlType',
  ],
}
</script>
