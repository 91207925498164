<template>
  <div class="comments-grid">
    <label class="text-gray-700 text-sm font-medium block">{{ $t('generic.comments') }}</label>

    <!-- Editor -->
    <form @submit.prevent="submit">
      <vue-editor
        class="mt-1 mb-6"
        v-model="content"
      />

<!--      <vk-loadable-button-->
<!--        :loading="loading"-->
<!--        html-type="submit"-->
<!--        type="primary">-->
<!--        {{ $t('projects.comment') }}-->
<!--      </vk-loadable-button>-->

      <loadable-button
          :loading="loading"
          html-type="submit"
          type="primary">
        {{ $t('projects.comment') }}
      </loadable-button>
    </form>

    <!-- Comments -->
    <Comment
      class="mt-2"
      :key="index"
      :id="comment.id"
      :avatar="comment.avatar"
      :creator="comment.creator"
      :content="comment.content"
      :created_at="comment.created_at"
      v-for="(comment, index) in sortedComments"
    />
  </div>
</template>

<script>
  import VkLoadableButton from '../global/vuikit/VkLoadableButton'
  import Comment from './Comment'
  import { VueEditor } from 'vue2-editor'
  import Quill from 'quill'
  import LoadableButton from "@/components/global/tailwind/LoadableButton";

  export default {
    props: [
      'taskId'
    ],
    computed: {
      comments() {
        return this.$store.getters['project/commentsByTaskId'](this.taskId)
      },
      sortedComments() {
        if (this.comments) {
          return this.comments.sort((i1, i2) => {
            return i1.created_at < i2.created_at ? 1 : -1
          })
        }
      }
    },
    data() {
      return {
        content: null,
        loading: false,
      }
    },
    created() {
      let Block = Quill.import('blots/block')
      Block.tagName = 'div'
      Quill.register(Block)
    },
    methods: {
      submit() {
        if (this.content) {
          this.loading = true
          this.$store.dispatch('project/saveComment', {
            appId: this.$route.params.appId,
            reference_id: this.taskId,
            reference_type: 'task',
            content: this.content
          }).then((res) => {
            if (res && res.success) {
              this.$toasted.success(this.$t('projects.comment_created'))
              this.content = null
            }
          }).finally(() => this.loading = false)
        }
      }
    },
    components: {
      LoadableButton,
      VkLoadableButton,
      VueEditor,
      Comment,
    }
  }
</script>
