<template>
  <Table
    :config="config"
    :data="foreignValues"
    @row-selected="selectRow">
    <template slot="controls">
      <IconDropdown
        icon="plus"
        @update="assign"
        position="bottom-left"
        :value="assignedServers"
        :values="transformedServers">
        <icon-button
          :tooltip="$t('web.append_server')"
          @click.prevent.stop=""
          type="button"
          ratio="0.85"
          icon="plus"
        />

        <vk-button
          slot="button"
          type="primary"
          class="uk-width-1-1@s"
          @click.stop="$router.push(createQueryParamLink('new'))">
          {{ $t('web.new_server') }}
        </vk-button>
      </IconDropdown>
    </template>
  </Table>
</template>

<script>
  import IconButton from '../../../../components/global/tailwind/IconButton'
  import IconDropdown from '../../../../components/projects/IconDropdown'
  import QueryParamMixin from '../../../../mixins/query-param'
  import Table from '../../../global/Table'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        servers: 'web/servers'
      }),
      transformedServers() {
        return this.servers.map(server => {
          return {
            id: server.id,
            name: server.name
          }
        })
      },
      assignedServers() {
        if (this.$route.params.customerId) {
          return this.servers
          .filter(server => server.customer_id)
          .map(server => server.id)
        }

        if (this.$route.params.providerId) {
          return this.servers
            .filter(server => server.provider_id === this.$route.params.providerId)
            .map(server => server.id)
        }
      }
    },
    data() {
      return {
        config: this.createTableConfig('web.no_servers_found', 'servers')
      }
    },
    methods: {
      assign($event) {
        let promise = null
        let payload = {
          reference_ids: $event,
          reference_type: 'server',
          appId: this.$route.params.appId,
        }

        if (this.$route.params.customerId) {
          payload.id = this.$route.params.customerId
          promise =this.$store.dispatch('customer/assignToCustomer', payload)
        }

        if (this.$route.params.providerId) {
          payload.id = this.$route.params.providerId
          promise = this.$store.dispatch('web/assignToProvider', payload)
        }

        if (promise) {
          /** @var Promise */
          promise.then(() => this.$store.dispatch('web/loadServers', payload))
        }
      }
    },
    mixins: [
      QueryParamMixin
    ],
    components: {
      IconButton,
      IconDropdown,
      Table
    }
  }
</script>