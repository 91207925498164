import Login from '../../views/auth/Login'
import Logout from '../../views/auth/Logout'
import Forgot from '../../views/auth/Forgot'
import Register from '../../views/auth/Register'

export default [
    {
        path: '/:lang/auth/login',
        component: Login,
        name: 'login'
    },
    {
        path: '/:lang/auth/logout',
        component: Logout
    },
    {
        path: '/:lang/auth/forgot',
        component: Forgot,
        name: 'forgot'
    },
    {
        path: '/:lang/auth/signup',
        component: Register,
    }
]