<template>
  <Detail
    :title="title('projects.new_project')"
    @submit="submit">

    <!-- Controls -->
    <template slot="header-controls">
      <button
          icon="check"
          type="submit"
          class="w-8 h-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
      </button>
    </template>

    <!-- Content -->
    <vk-grid class="uk-margin-top">
      <labeled-input
        required
        v-model="model.name"
        class="uk-width-1-1@s">
        {{ $t('generic.name') }}
      </labeled-input>

      <labeled-input
        required
        type="textarea"
        v-model="model.comment"
        class="uk-width-1-1@s uk-margin-small-top">
        {{ $t('generic.note') }}
      </labeled-input>
    </vk-grid>
  </Detail>
</template>

<script>
import LabeledInput from '../../components/global/inputs/LabeledInput'
import VkIconButton from '../../components/global/vuikit/VkIconButton'
import Comments from '../../components/projects/Comments'
import Detail from '../../components/layout/details/Detail'
import DetailMixin from '../../mixins/detail'
import {mapGetters} from 'vuex'

export default {
  created() {
    this.setup(
      'projectId',
      'project/projectById',
      'projects.project_created')
  },
  methods: {
    submit() {
      return this.saveModel('project/saveProject').then(() => {
        if (this.isCreateMode) {
          this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/projects/' + this.model.id + '/view')
        }
      })
    },
  },
  mixins: [
    DetailMixin
  ],
  components: {
    Detail,
    Comments,
    LabeledInput,
    VkIconButton,
  }
}
</script>