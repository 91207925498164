<template>
  <Detail
    :title="title('projects.new_task')"
    @submit="submit">

    <!-- Controls -->
    <template slot="header-controls">
      <button
          icon="check"
          type="submit"
          class="w-8 h-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
      </button>
      <ConfirmModal
        v-if="!isCreateMode"
        @confirm="deleteTask"
        :title="$t('projects.confirm_delete_task_title')"
        :message="$t('projects.confirm_delete_task_message')">
        <button
            icon="trash"
            type="button"
            v-if="!isCreateMode"
            class="uk-margin-small-left"
            :tooltip="tooltip('generic.delete')"
        >
          <svg class="w-6 h-6" fill="none" stroke="lightgray" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
        </button>
      </ConfirmModal>
    </template>

    <!-- Content -->
      <vk-grid class="uk-margin-top">
        <labeled-input
          required
          v-model="model.name"
          class="uk-width-1-1@s">
          {{ $t('generic.name') }}
        </labeled-input>

        <labeled-input
          type="select"
          :values="status"
          v-model="model.status"
          class="uk-width-1-2@s uk-margin-small">
          {{ $t('generic.status') }}
        </labeled-input>

        <labeled-input
          type="select"
          :values="projectValues"
          v-model="model.project_id"
          class="uk-width-1-2@s uk-margin-small">
          {{ $t('generic.project') }}
        </labeled-input>

        <labeled-input
          type="user"
          :values="userValues"
          v-model="model.assignee_id"
          class="uk-width-1-2@s uk-margin-small">
          {{ $t('projects.assignee') }}
        </labeled-input>

        <labeled-input
          type="user"
          :multiple="true"
          :values="userValues"
          v-model="model.follower"
          class="uk-width-1-2@s uk-margin-small">
          {{ $t('projects.follower') }}
        </labeled-input>s

        <labeled-input
          type="textarea"
          v-model="model.comment"
          class="uk-width-1-1@s uk-margin-small">
          {{ $t('generic.description') }}
        </labeled-input>
      </vk-grid>

      <!-- Resources -->
      <div class="uk-margin-bottom">
        <label class="uk-form-label">{{ $t('generic.resources') }}</label>
        <Resources
          @update="assignResourcesToTask"
          :value="model.resources"
          :show-resources="true"
          resource-type="task"
          position="top-left"
          :key="model.id"
        />
      </div>

      <!-- Comments -->
      <Comments
        :taskId="model.id"
      />
  </Detail>
</template>

<script>
  import ConfirmModal from '../../components/global/modals/ConfirmModal'
  import LabeledInput from '../../components/global/inputs/LabeledInput'
  import VkIconButton from '../../components/global/vuikit/VkIconButton'
  import Resources from '../../components/projects/Resources'
  import Detail from '../../components/layout/details/Detail'
  import Comments from '../../components/projects/Comments'
  import DetailMixin from '../../mixins/detail'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        projects: 'project/projects',
        users: 'app/users',
      }),
      projectValues() {
        return [{ id: null, name: this.$t('projects.no_project') }].concat(this.projects)
      },
      userValues() {
        return [{ label: 'Kein Benutzer ausgewählt', icon: 'images/icons/check.svg' }]
          .concat(this.users.map(item => {
            return {
              ...item,
              label: item.fullname,
              avatar: item.avatar,
            }
          }))
      }
    },
    data() {
      return {
        status: [
          { id: 'open', name: this.$t('projects.task_status_open') },
          { id: 'waiting', name: this.$t('projects.task_status_waiting') },
          { id: 'in_progress', name: this.$t('projects.task_status_in_progress') },
          { id: 'waiting_for_response', name: this.$t('projects.task_status_waiting_for_response') },
          { id: 'testing', name: this.$t('projects.task_status_testing') },
          { id: 'finished', name: this.$t('projects.task_status_finished') },
        ],
      }
    },
    created() {
      this.setup(
        'taskId',
        'project/taskById',
        'projects.task_created',
      'projects.task_updated',
      'projects.task_deleted')

      // Predefine default model
      if (!this.model.status) {
        this.model.status = 'open'
      }

      if ((this.model && !this.model.project_id)
        && this.$route.query[this.routeParam]
        && this.routeParam === 'taskId') {
        this.model.project_id = this.$route.params.projectId
      }

      this.loading = true
      let payload = {
        appId: this.$route.params.appId
      }
      Promise.all([
        this.$store.dispatch('project/loadProjects', payload),
        this.$store.dispatch('app/loadUsers', payload)
      ]).finally(() => this.loading = false)
    },
    methods: {
      submit() {
        this.saveModel('project/saveTask').then(() => {
          if (this.isCreateMode) {
            if (this.$route.params[this.routeParam]) {
              return this.redirect('/tasks/' + this.model.id)
            }
            this.redirect('/projects/' + this.model.project_id + '/view?taskId=' + this.model.id)
          }

          return this.$store.dispatch('project/loadProjects', {
            appId: this.$route.params.appId
          })
        })
      },
      deleteTask() {
        this.deleteModel('project/deleteTask').then((res) => {
          if (res && res.success) {
            if (this.$route.params[this.routeParam]) {
              return this.redirect('/tasks')
            }
            return this.redirect('/projects/' + this.model.project_id + '/view')
          }
        })
      },
      /**
       * Returns updated array of values for a specific type (server, domain, page, provider, customer)
       * @param $event
       */
      assignResourcesToTask($event) {
        if (!this.model.resources) {
          this.model.resources = []
        }

        // Transform to array - if it is not
        if (!Array.isArray($event.value)) {
          $event.value = [$event.value]
        }

        this.model.resources = this.model.resources.filter(resource => resource.type !== $event.type)
        $event.value.forEach(value => {
          this.model.resources.push({
            type: $event.type,
            value: value
          })
        })
      }
    },
    mixins: [
      DetailMixin
    ],
    components: {
      ConfirmModal,
      Detail,
      Comments,
      Resources,
      LabeledInput,
      VkIconButton,
    }
  }
</script>