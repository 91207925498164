<template>
  <div class="h-full flex flex-col">
    <div class="flex text-xl items-center h-12 p-8 bg-secondary justify-between">
      <div>{{ title }}</div>
      <div class="uk-flex">
        <ConfirmModal
            @confirm="deleteProject"
            :title="$t('projects.confirm_delete_project_title')"
            :message="$t('projects.confirm_delete_project_message')">
          <icon-button
              icon="trash"
              type="button"
              :tooltip="$t('projects.delete_project')"
          />
        </ConfirmModal>
      </div>
    </div>

    <div class="z-10 flex pt-2 pb-1 pr-8 bg-blue-800 justify-between">
      <slot name="controls" />
    </div>

    <div class="content-wrapper w-full flex flex-grow overflow-hidden">
      <div class="w-2/12 p-8 flex flex-shrink-0 items-center flex-col justify-center bg-secondary">
        <FeatureTeaser />
      </div>

      <div class="w-4/12 h-full flex-shrink-0 bg-blue-50">
        <slot name="middle" />
      </div>

      <div class="w-6/12 overflow-y-auto bg-white">
        <slot
            name="right"
            v-if="$slots['right']"
        />

        <template v-else>
          <FeatureTeaser />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmModal from '../../components/global/modals/ConfirmModal'
import IconButton from '../../components/global/tailwind/IconButton'
import FeatureTeaser from '../../components/projects/FeatureTeaser'

export default {
  props: [
    'title'
  ],
  methods: {
    deleteProject() {
      this.$store.dispatch('project/deleteProject', {
        appId: this.$route.params.appId,
        id: this.$route.params.projectId
      }).then(res => {
        if (res && res.success) {
          this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/projects')
        }
      })
    },
  },
  components: {
    ConfirmModal,
    FeatureTeaser,
    IconButton
  }
}
</script>