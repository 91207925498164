import Vue from 'vue'
import App from './App.vue'
import store from './store'
import i18n from './locales'
import Vuikit from 'vuikit'
import router from './router'
import VueToasted from 'vue-toasted'
import VuikitIcons from '@vuikit/icons'
import VueFilters from './helpers/filters'
import '@vuikit/theme'
import VueGtm from 'vue-gtm';
import VueIntercom from '@mathieustan/vue-intercom';
import './assets/tailwind.css';


Vue.use(Vuikit)
Vue.use(VueFilters)
Vue.use(VueToasted, {
  position: 'top-right',
  duration: 2000
})
Vue.use(VuikitIcons)

if (process.env.VUE_APP_ENV === 'production' || process.env.VUE_APP_ENV === 'prod') {
  Vue.use(VueGtm, {
    id: "GTM-WCTFT4Z",
    defer: false,
    compatibility: true,
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false
  });
  Vue.use(VueIntercom, { appId: 'ktc6wkox' });
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
