import APIFactory from '@/api/factory'

export default {
  namespaced: true,
  state: {
    email: null,
    appId: null,
    token: null,
    lastname: null,
    firstname: null,
  },
  mutations: {
    setEmail(state, email) {
      state.email = email
    },
    setAppId(state, appId) {
      state.appId = appId
    },
    setToken(state, token) {
      state.token = token
    },
    setFirstname(state, firstname) {
      state.firstname = firstname
    },
    setLastname(state, lastname) {
      state.lastname = lastname
    },
    setAvatar(state, avatar) {
      state.avatar = avatar
    }
  },
  actions: {
    signup({commit}, payload) {
      return APIFactory.signup(payload.lastname,
        payload.firstname,
        payload.email,
        payload.password,
        payload.password_confirm)
    },
    login({commit}, payload) {
      return APIFactory.login(payload.email, payload.password).then(res => {
          if (res && res.success) {
            commit('setEmail', payload.email)
            commit('setAppId', res.data.appId)
            commit('setToken', res.data.token)
            commit('setLastname', res.data.lastname)
            commit('setFirstname', res.data.firstname)
            commit('setAvatar', res.data.avatar)
          }
          return res
      })
    },
    logout({commit}) {
      return APIFactory.logout().then(() => {
        commit('setEmail', null)
        commit('setAppId', null)
        commit('setToken', null)
        commit('setLastname', null)
        commit('setFirstname', null)
        commit('setAvatar', null)
      })
    },
    update({commit, state}, payload) {
      return APIFactory.updateUser(
        payload.lastname,
        payload.firstname,
        payload.password,
        payload.password_confirm).then(res => {
        commit('setLastname', res.data.lastname)
        commit('setFirstname', res.data.firstname)
        return res
      })
    },
    forgot({commit}, payload) {
      return APIFactory.reset(payload.email)
    }
  },
  getters: {
    email: (state) => state.email,
    token: (state) => state.token,
    avatar: (state) => state.avatar,
    lastname: (state) => state.lastname,
    firstname: (state) => state.firstname,
    fullname: (state) => state.firstname + ' ' + state.lastname
  }
}
