<template>
  <div>
    <label class="text-gray-700 text-sm font-medium block">
      <slot />
    </label>
    <template v-if="isBasicType">
      <input
        :type="type"
        :value="value"
        :required="required"
        :disabled="disabled"
        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2 outline-none duration-200 transition-all"
        @input="$emit('input', $event.target.value)"
      />
    </template>

    <!-- Select -->
    <template v-else-if="type === 'select'">
      <select
        :value="value"
        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2 outline-none duration-200 transition-all"
        @input="$emit('input', $event.target.value)">
        <option
          :value="val.id"
          v-for="val in values">
          {{ val.name }}
        </option>
      </select>
    </template>

    <!-- Textarea -->
    <template v-else-if="type === 'textarea'">
      <textarea
        rows="5"
        :value="value"
        :disabled="disabled"
        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2 outline-none duration-200 transition-all"
        @input="$emit('input', $event.target.value)"
      />
    </template>

    <!-- User -->
    <template v-else-if="type === 'user'">
      <UserInput
        :value="value"
        :values="values"
        :multiple="multiple"
        @input="$emit('input', $event)"
      />
    </template>
  </div>
</template>

<script>
  import UserInput from '@/components/global/inputs/UserInput'

  export default {
    props: [
      'type',
      'value',
      'values',
      'required',
      'disabled',
      'multiple',
    ],
    computed: {
      isBasicType() {
        return this.type !== 'select'
          && this.type !== 'textarea'
          && this.type !== 'user'
      }
    },
    components: {
      UserInput
    },
  }
</script>