<template>
  <Detail
    :title="title('web.new_page')"
    @submit="submit">

    <template slot="header-controls">
      <button
          icon="check"
          type="submit"
          class="w-8 h-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
      </button>
      <ConfirmModal
        :title="$t('web.confirm_delete_page_title')"
        :message="$t('web.confirm_delete_page_message')"
        @confirm="deletePage">
        <button
            icon="trash"
            type="button"
            v-if="!isCreateMode"
            class="uk-margin-small-left"
            :tooltip="tooltip('generic.delete')"
        >
          <svg class="w-6 h-6" fill="none" stroke="lightgray" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
        </button>
      </ConfirmModal>
    </template>

    <vk-grid>
      <labeled-input
        required
        v-model="model.name"
        class="uk-width-1-1@s">
        {{ $t('generic.name') }}
      </labeled-input>

      <labeled-input
        type="select"
        :values="domainValues"
        v-model="model.domain_id"
        class="uk-width-1-2@s uk-margin-small">
        {{ $t('generic.domain') }}
      </labeled-input>

      <labeled-input
        v-model="model.url"
        class="uk-width-1-2@s uk-margin-small">
        {{ $t('generic.url') }}
      </labeled-input>

      <labeled-input
        type="select"
        :values="customerValues"
        v-model="model.customer_id"
        class="uk-width-1-2@s uk-margin-small">
        {{ $t('generic.customer') }}
      </labeled-input>

      <labeled-input
        v-model="model.tag"
        class="uk-width-1-2@s uk-margin-small">
        {{ $t('generic.tag') }}
      </labeled-input>

      <labeled-input
        type="select"
        :values="providerValues"
        v-model="model.provider_id"
        class="uk-width-1-2@s uk-margin-small">
        {{ $t('generic.provider') }}
      </labeled-input>

      <labeled-input
        type="select"
        :values="serverValues"
        v-model="model.server_id"
        class="uk-width-1-2@s uk-margin-small">
        {{ $t('generic.server') }}
      </labeled-input>

      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <label>
          <input
            v-model="model.monitoring"
            class="uk-checkbox"
            type="checkbox">
          {{ $t('web.pages.monitoring') }}
          <br>
          <small>({{ $t('web.pages.monitoring_sub', { email: $store.getters['auth/email'] }) }})</small>
        </label>
      </div>

      <labeled-input
        type="textarea"
        v-model="model.comment"
        class="uk-width-1-1@s uk-margin-small">
        {{ $t('generic.note') }}
      </labeled-input>
    </vk-grid>
  </Detail>
</template>

<script>
  import DetailMixin from '../../mixins/detail'
  import Detail from '../../components/layout/details/Detail'
  import VkIconButton from '../../components/global/vuikit/VkIconButton'
  import LabeledInput from '../../components/global/inputs/LabeledInput'
  import {mapGetters} from "vuex";
  import ConfirmModal from "@/components/global/modals/ConfirmModal";

  export default {
    computed: {
      ...mapGetters({
        customers: 'customer/customers',
        providers: 'web/providers',
        domains: 'web/domains',
        servers: 'web/servers',
      }),
      customerValues() {
        return [{name: this.$t('customers.no_customer')}].concat(this.customers)
      },
      providerValues() {
        return [{name: this.$t('web.no_provider')}].concat(this.providers)
      },
      domainValues() {
        return [{name: this.$t('web.no_domain')}].concat(this.domains)
      },
      serverValues() {
        return [{name: this.$t('web.no_server')}].concat(this.servers)
      }
    },
    created() {
      this.setup(
        'pageId',
        'web/pageById',
      'web.page_created',
      'web.page_updated',
      'web.page_deleted')

      // Prefill form
      if (this.isCreateMode) {
        if (this.$route.params.serverId) {
          this.model.server_id = this.$route.params.serverId
        }

        if (this.$route.params.domainId) {
          this.model.domain_id = this.$route.params.domainId
        }

        if (this.$route.params.providerId) {
          this.model.provider_id = this.$route.params.providerId
        }

        if (this.$route.params.customerId) {
          this.model.customer_id = this.$route.params.customerId
        }
      }

      // Loading referencing models
      this.prefetchData([
        'customer/loadCustomers',
        'web/loadProviders',
        'web/loadDomains',
        'web/loadDomains'
      ])
    },
    methods: {
      submit() {
        return this.saveModel('web/savePage').then(() => {
          if (this.isCreateMode) {
            if (this.isViewCalledByQueryParam) {
              return this.$router.push({query: {[this.routeParam]: this.model.id}})
            }
            return this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/pages/' + this.model.id)
          }
        })
      },
      deletePage() {
        return this.deleteModel('web/deletePage').then((res) => {
          if (res && res.success) {
            this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/pages')
          }
        })
      }
    },
    mixins: [
      DetailMixin
    ],
    components: {
      ConfirmModal,
      VkIconButton,
      LabeledInput,
      Detail
    }
  }
</script>