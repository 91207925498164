<template></template>

<script>
export default {
  created() {
    this.$store.dispatch('auth/logout').then(() => {
      if (this.$intercom) {
        this.$intercom.trackEvent('logout');
        this.$intercom.shutdown();
      }
      this.$router.push('/' + this.$i18n.locale + '/auth/login')
    })
  }
}
</script>
